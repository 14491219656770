import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        margin: '10rem 0',
        textAlign: 'center'
      }}
    >
      <div
        style={{
          fontSize: '10rem'
        }}
      >
        404
      </div>{' '}
      <div
        style={{
          fontSize: '5rem'
        }}
      >
        Page Not Found
      </div>
    </div>
  );
};
export default NotFound;
